import React from "react";
import Helmet from "react-helmet";
import { ListGroup } from "react-bootstrap";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";

import Layout from "../components/Layout";

export default () => (
  <Layout>
    <SEO
      title="Renewable Energy Ground Soruce Heat Pumps GSHP"
      description="Have issues with your Ground source heat pump? Contact us to find our more information and set up a visit with one of our engineers"
      keywords="Ground source heat pumps, Ground source heat pumps london, Ground source heat pumps central london, Ground source heat pumps potters bar, Ground source heat pumps hertfordshire, Ground source heat pumps instllation, Ground source heat pumps repair, Ground source heat pumps servicing"
    />

    <div class="white-segment text-left">
      <h1 class="heading-color">Ground source heat pumps</h1>
      <p>
        Ground source heat pumps are a good option as they will work well
        whatever the weather due to the primary pipework being buried
        underground.
      </p>
      <Jumbotron imgClassName="img8" />
      <h3 class="pt-2 pb-2">Main benefits of running a heat pump</h3>
      <ListGroup variant="flush">
        <ListGroup.Item>Produce 0 % emissions</ListGroup.Item>
        <ListGroup.Item>
          Can dramatically reduce your energy bills, but cost of installation
          needs to be considered
        </ListGroup.Item>
        <ListGroup.Item>Very low maintenance</ListGroup.Item>
        <ListGroup.Item>
          Provide heat for radiators and hot water
        </ListGroup.Item>
        <ListGroup.Item>
          Part of the government’s renewable heat incentive scheme (RHI)
        </ListGroup.Item>
      </ListGroup>
    </div>
  </Layout>
);
